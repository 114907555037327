/** @jsx jsx */
import { Col, Container, Row } from "react-bootstrap";
import { jsx } from '@emotion/react'
import LogoPPA from '../assets/images/lp/logotipo_atrio.svg'
import SucessoImg from '../assets/images/lp/sucesso-img.png'

import { 
  sucessContent,
  logoImg, 
}  from '../assets/styles/LP.styles'

const ContentSection = () => {
  return (
    <section css={sucessContent}>
      <Container>
        <Row>
          <Col lg={12} md={12} sm={12}>
            <div css={logoImg}><img src={LogoPPA} alt="Pré-Vestibular PPA" title="Pré-Vestibular PPA" /></div>
            <div>
              <div style={{ textAlign: 'center' }}><img src={SucessoImg} /></div>
              <h2>Parabéns pela sua inscrição foi realizada com sucesso</h2>
              <a href="/">VOLTAR A NAVEGAR</a>
            </div>
            </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ContentSection;
